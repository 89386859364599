import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import "/src/tailwind.css";

document.addEventListener("DOMContentLoaded", function () {
  if (!sessionStorage.getItem("session_id")) {
    sessionStorage.setItem(
      "session_id",
      "session_" + Math.random().toString(36).substr(2, 9) + "_" + Date.now()
    );
  }

  let sessionId = sessionStorage.getItem("session_id");

  if (!sessionStorage.getItem("referrer")) {
    sessionStorage.setItem("referrer", document.referrer);
  }

  let trackingRef = sessionStorage.getItem("referrer") || "direct";

  let mapLoaded = false;

  window.addEventListener("mousemove", loadMapbox, { once: true });
  window.addEventListener("touchstart", loadMapbox, { once: true });
  window.addEventListener("scroll", loadMapbox, { once: true });

  window.addEventListener("mousemove", loadResources, { once: true });
  window.addEventListener("touchstart", loadResources, { once: true });
  window.addEventListener("scroll", loadResources, { once: true });

  function loadMapbox() {
    if (mapLoaded) return;
    mapLoaded = true;
    initMap();
  }

  function loadResources() {
    window.Futy = { key: "665df17a3fde6" };
    (function (e, t) {
      var n = e.createElement(t);
      n.async = true;
      var f = window.Promise && window.fetch ? "modern.js" : "legacy.js";
      n.src = "https://v1.widget.futy.io/js/futy-widget-" + f;
      var r = e.getElementsByTagName(t)[0];
      r.parentNode.insertBefore(n, r);
    })(document, "script");

    const gtagScript1 = document.createElement("script");
    gtagScript1.src =
      "https://www.googletagmanager.com/gtag/js?id=G-E5LGRGZEZP";
    gtagScript1.async = true;
    document.body.appendChild(gtagScript1);

    const gtagScript2 = document.createElement("script");
    gtagScript2.src =
      "https://www.googletagmanager.com/gtag/js?id=AW-11171007225";
    gtagScript2.async = true;
    document.body.appendChild(gtagScript2);

    gtagScript1.onload = function () {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-E5LGRGZEZP");
    };

    gtagScript2.onload = function () {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-11171007225");
    };

    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 5010227, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

    // Controleer of de carousel-elementen bestaan
    const carousel = document.getElementById("carousel");
    const nextBtn = document.getElementById("nextBtn");
    const prevBtn = document.getElementById("prevBtn");
    const nextBtnMobile = document.getElementById("nextBtnMobile");
    const prevBtnMobile = document.getElementById("prevBtnMobile");

   
    if (carousel) {
      let currentIndex = 0;
      const items = carousel.children;
      const totalItems = items.length;
      let autoScroll;
      let isAutoScrolling = true;
    
      function getVisibleItems() {
        if (window.innerWidth >= 1024) {
          return 3; // lg
        } else if (window.innerWidth >= 768) {
          return 2; // md
        } else {
          return 1; // mobiel
        }
      }
    
      function updateCarousel() {
        const visibleItems = getVisibleItems();
        const itemWidth = carousel.offsetWidth / visibleItems;
        const maxIndex = totalItems - visibleItems;
    
        // Zorg ervoor dat de currentIndex niet verder gaat dan de limieten
        if (currentIndex < 0) {
          currentIndex = 0; // Stop aan het begin
        }
        if (currentIndex > maxIndex) {
          currentIndex = maxIndex; // Stop aan het einde
        }
    
        const translateX = -currentIndex * itemWidth;
        carousel.style.transform = `translateX(${translateX}px)`;
      }
    
      function startAutoScroll() {
        autoScroll = setInterval(() => {
          const visibleItems = getVisibleItems();
          const maxIndex = totalItems - visibleItems;
    
          if (currentIndex < maxIndex) {
            currentIndex++;
            updateCarousel();
          } else {
            stopAutoScroll(); // Stop autoplay als het einde is bereikt
          }
        }, 5000);
      }
    
      function stopAutoScroll() {
        clearInterval(autoScroll);
        isAutoScrolling = false;
      }
    
      function handleInteraction() {
        if (isAutoScrolling) {
          stopAutoScroll();
        }
      }
    
      // Event listeners voor desktop knoppen
      if (nextBtn && prevBtn) {
        nextBtn.addEventListener("click", () => {
          const visibleItems = getVisibleItems();
          const maxIndex = totalItems - visibleItems;
    
          if (currentIndex < maxIndex) {
            currentIndex++;
            updateCarousel();
            handleInteraction();
          }
        });
    
        prevBtn.addEventListener("click", () => {
          if (currentIndex > 0) {
            currentIndex--;
            updateCarousel();
            handleInteraction();
          }
        });
      }
    
      // Event listeners voor mobiele knoppen
      if (nextBtnMobile && prevBtnMobile) {
        nextBtnMobile.addEventListener("click", () => {
          const visibleItems = getVisibleItems();
          const maxIndex = totalItems - visibleItems;
    
          if (currentIndex < maxIndex) {
            currentIndex++;
            updateCarousel();
            handleInteraction();
          }
        });
    
        prevBtnMobile.addEventListener("click", () => {
          if (currentIndex > 0) {
            currentIndex--;
            updateCarousel();
            handleInteraction();
          }
        });
      }
    
      // Stop autoplay bij touchinteractie
      carousel.addEventListener("touchstart", () => {
        handleInteraction();
      });
    
      // Update carousel bij venstergrootte verandering
      window.addEventListener("resize", () => {
        updateCarousel();
        handleInteraction();
      });
    
      // Initialiseer de carousel positie en start autoplay
      updateCarousel();
      startAutoScroll();
    }
    
    
    
    
  }

  function initMap() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoibWFyY2gtZGV2IiwiYSI6ImNsMmh5aDF4MTBocHgzaXA5Ync1dHg1MmoifQ.5dZmY5XbqHX9QlF2K72obg";
    window.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [4.399708, 51.219448],
      zoom: 7,
      scrollZoom: false,
    });

    map.on("load", function () {
      const placeholderImage = document.querySelector("#mapPlaceholder");
      if (placeholderImage) {
        placeholderImage.style.display = "none";
      }
    });

    map.on("click", function () {
      map.scrollZoom.enable();
    });

    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: { enableHighAccuracy: true },
        trackUserLocation: true,
      })
    );

    map.addControl(new mapboxgl.NavigationControl());
  }

  document
    .getElementById("property-info-form")
    .addEventListener("submit", async function (event) {
      event.preventDefault();

      var adresValue = document.getElementById("adres").value;

      if (adresValue === "") {
        if (sessionStorage.getItem("selectedAddress") !== null) {
          adresValue = sessionStorage.getItem("selectedAddress");
        } else {
          adresValue = "";
        }
      }

      var latitude = document.getElementById("latitude").value;
      var longitude = document.getElementById("longitude").value;
      var buildableArea = document.getElementById("buildableArea").value;
      var bedroomCount = document.getElementById("bedroomCountNumber").value;
      var buildingCondition =
        document.getElementById("buildingCondition").value;
      var propertyType = document.getElementById("propertyType").value;
      var energyConsumptionPerSqm = document.getElementById(
        "energyConsumptionPerSqm"
      ).value;
      var surfacePlot = document.getElementById("surfacePlot").value;

      sessionStorage.setItem(
        "prediction_id",
        "prediction_" +
          Math.random().toString(36).substr(2, 9) +
          "_" +
          Date.now()
      );
      let predictionId = sessionStorage.getItem("prediction_id");

      const query = `
                query {
                    predictPrice(predictionInput: {
                        sessionId: "${sessionId}",
                        predictionId: "${predictionId}",
                        platform: "${navigator.platform}",
                        userAgent: "${navigator.userAgent}",
                        screenWidth: ${parseInt(window.screen.width)},
                        trackingRef: "${trackingRef}",
                        address: "${adresValue}",
                        latitude: ${latitude},
                        longitude: ${longitude},
                        buildableArea: ${buildableArea},
                        bedroomCount: ${bedroomCount},
                        buildingCondition: ${buildingCondition},
                        energyConsumptionPerSqm: ${energyConsumptionPerSqm},
                        surfacePlot: ${surfacePlot},
                        propertyType: ${propertyType}
                    })
                }
            `;

      try {
        const response = await fetch(
          "https://woon-info-graphql-hoshtgewlq-ew.a.run.app/graphql",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ query }),
          }
        );

        const data = await response.json();
        var predictedPrice = parseInt(data.data.predictPrice);

        var predictionUrl =
          "prediction?" +
          new URLSearchParams({
            adres: adresValue,
            latitude: latitude,
            longitude: longitude,
            buildableArea: buildableArea,
            bedroomCount: bedroomCount,
            buildingCondition: buildingCondition,
            propertyType: propertyType,
            energyConsumptionPerSqm: energyConsumptionPerSqm,
            surfacePlot: surfacePlot,
            predictedPrice: predictedPrice,
          }).toString();

        window.location.href = predictionUrl;
      } catch (error) {
        console.error("Fout bij het uitvoeren van de GraphQL-query:", error);
      }
    });
});
